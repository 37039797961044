body {
  background-color: #000;
  color: #CCC;
  left: 10%;
  line-height: 1.8;
  position: relative;
  width: 80%;
  max-width: 40em;
  margin: 0 0 3em 0;
}

a {
  text-decoration: none;
  border-bottom: dashed 0.1em #0F0;
}

a:link {
  color: #0FF
}

a:visited {
  color: #F0F
}

a:hover {
  border-color: #F00;
}

a:active {
  border-color: #F00;
}

ul, ol, dl {
  margin: 0;
  padding: 0;
  list-style: none;
}

.AA {
  font-family: 'ＭＳ Ｐゴシック', 'MS PGothic', RobotoJAA, Monapo, 'IPA モナー Pゴシック', IPAMonaPGothic, Mona, '梅Pゴシック', Textar, sans-serif;
}

.Breadcrumbs li {
  display: inline-block;
}

.Breadcrumbs li.parent::after {
  content: ' > ';
  margin-right: 0.5em;
}

.dlTable {
  display: grid;
  grid-template-columns: max-content auto;
}

.dlTable dt {
  margin-right: 1em;
}

.dlTable dd {
  margin: 0;
}

.group > li {
  margin-bottom: 1em;
}
